class DiscoFries {
  private static instance: DiscoFries
  public static get Instance () : DiscoFries {
    if (!DiscoFries.instance) {
      DiscoFries.instance = new DiscoFries()
    }
    return DiscoFries.instance
  }

  constructor () {
    let weakSelf = this
    setTimeout(() => {
      let querystring = weakSelf.parseQuerystring()
      if (!querystring) {
        querystring = {tag: "draws"}
      }
      // get tag from querystring
      // build grid with tag
      let tag = querystring.tag
      if (tag) {
        this.buildGrid(tag)
      }
    }, 100)
  }

  public parseQuerystring () {
    let querystring = document.location.search
    let querystringObject: any = {}
    if (querystring) {
      let querystringArray = querystring.split("?")[1].split("&")
      for (let item of querystringArray) {
        let key = item.split("=")[0]
        let value = item.split("=")[1]
        querystringObject[key] = value
      }
      return querystringObject
    }
    return null
  }

  public async buildGrid (tag: string) {
    let discoDatabase: any
    // Load discoDatabase from "./disco.json" using fetch
    discoDatabase = await this.fetchDatabase()

    if (!discoDatabase) {
      throw new Error("Cannot load database")
    }

    let discoGrid = document.querySelector("div.container")
    if (!discoGrid) {
      throw new Error(`Cannot find discoGrid`)
    }

    Array.from(discoGrid.querySelectorAll("a")).forEach(el => {
      el.remove()
    })

    // Get the template for nav-item-template and using the names from the directories collection of discoDatabase; produce new nav items in the parent ul for each unique string.

    let navItemTemplate = document.querySelector("#nav-item-template") as HTMLTemplateElement
    if (!navItemTemplate) {
      throw new Error(`Cannot find navItemTemplate`)
    }

    let navItemContent = navItemTemplate.content
    let navItemClone = document.importNode(navItemContent, true)
    let navItemCloneLI = navItemClone.querySelector("li")
    if (!navItemCloneLI) {
      throw new Error(`Cannot find navItemTemplateCloneLi`)
    }

    let uniqueEntires = Array.from(new Set(discoDatabase.directories.map((item: any) => item.name as string))) as Array<string>
    let navUL = document.querySelector("ul.nav")
    if (!navUL) {
      throw new Error(`Cannot find navItemTemplateCloneUl`)
    }

    for (const item of uniqueEntires) {
      // Nav items contain an anchor that will be updated to ?tag=name and have the innerText of the anchor set to name
      let navItemTemplateCloneLiClone = navItemCloneLI.cloneNode(true) as HTMLLIElement

      let navItemTemplateCloneLiCloneAnchor = navItemTemplateCloneLiClone.querySelector("a")
      if (!navItemTemplateCloneLiCloneAnchor) {
        throw new Error(`Cannot find navItemTemplateCloneLiCloneAnchor`)
      }
      navItemTemplateCloneLiCloneAnchor.setAttribute("href", `?tag=${item}`)
      navItemTemplateCloneLiCloneAnchor.innerText = item
      navUL.appendChild(navItemTemplateCloneLiClone)
    }


    // Get the template for image and populate the a.href, img.src, and img.alt
    // Append new image instances to the body
    //
    let template = document.querySelector("#image-template") as HTMLTemplateElement
    let images = discoDatabase.filepaths.filter((fp: any) => fp.tags.includes(tag))
    for (const image of images) {
      let clone = template.content.cloneNode(true) as HTMLElement
      let a = clone.querySelector("a") as HTMLAnchorElement
      a.href = image.finalWWWPath
      let img = clone.querySelector("img") as HTMLImageElement
      img.src = image.thumbnailWWWPath
      img.alt = image.name
      discoGrid.appendChild(clone)
    }
  }

  public async fetchDatabase () {
    let discoDatabaseResponse: any = await fetch("./disco.json")
    if (discoDatabaseResponse) {
      let discoDatabase = await discoDatabaseResponse.json()
      return discoDatabase
    }
  }
}

declare namespace window {
  let disco: DiscoFries
}

window["disco"] = DiscoFries.Instance

export {DiscoFries}
